/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import { animated, useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';

import {
  AppStore,
  Box,
  Button,
  ChevronDown,
  Col,
  Collapse,
  Container,
  Facebook,
  Flex,
  FullWidthContainer,
  GooglePlay,
  Icon,
  Instagram,
  Linkedin,
  Row,
  SecondaryText,
  Soundcloud,
  Spotify,
  StrongText,
  Tiktok,
  Twitter,
  useMediaQuery,
  useToggle,
  Vimeo,
  Xceed,
  XceedLogo,
  Youtube,
} from '@xceedsrl/jukebox';

import { LangSwitcher, useTranslation } from 'i18n/';
import { useCities } from 'store/cities';

import Link from './CustomLink';

const RotationCol = animated(Col);

const BORDER_TOP = '1px solid #e2e4e5';

const BUSINESS = [
  { label: 'common:listEvents', href: '/business#your-verified-page' },
  { label: 'common:sellTicketsOnline', href: '/business#online-bookings' },
  { label: 'common:manageGuestsList', href: '/business#manage-guest-lists' },
  { label: 'common:accessControl', href: '/business#access-control' },
  { label: 'common:tableManagement', href: '/business#table-management' },
  { label: 'common:siaeAutomatedTicketOffice', href: '/business#biglietteria-automatizzata-siae' },
  { label: 'common:xceedNightgraph', href: 'https://nightgraph.xceed.me/select/city' },
];

const POPULAR_VENUES = [
  { name: 'Audiodrome Live Club', slug: 'audiodrome-live-club', geo: 'torino' },
  { name: 'Bloody Louis', slug: 'bloody-louis', geo: 'brussels' },
  { name: 'Fabrik Madrid', slug: 'fabrik-madrid', geo: 'madrid' },
  { name: 'Gare Porto', slug: 'gare-porto', geo: 'porto' },
  { name: 'Input Dance Club', slug: 'input-dance-club', geo: 'barcelona' },
  { name: 'La Riviera', slug: 'la-riviera', geo: 'madrid' },
  { name: 'Pacha Barcelona', slug: 'pacha-barcelona', geo: 'barcelona' },
  { name: 'Razzmatazz', slug: 'razzmatazz', geo: 'barcelona' },
  { name: 'Sala Sonora', slug: 'sala-sonora', geo: 'bilbao' },
  { name: 'Spazio Novecento', slug: 'spazio-novecento', geo: 'roma' },
];

const TOP_HEADLINERS = [
  { name: 'Amelie Lens', slug: 'amelie-lens' },
  { name: 'Boris Brejcha', slug: 'boris-brejcha' },
  { name: 'Carl Cox', slug: 'carl-cox' },
  { name: 'Charlotte de Witte', slug: 'charlotte-de-witte' },
  { name: 'Jamie Jones', slug: 'jamie-jones' },
  { name: 'Marco Carola', slug: 'marco-carola' },
  { name: 'Michael Bibi', slug: 'michael-bibi' },
  { name: 'Nina Kraviz', slug: 'nina-kraviz' },
  { name: 'Peggy Gou', slug: 'peggy-gou' },
  { name: 'Wade', slug: 'wade' },
];

const PAGES = [
  { label: 'common:brand', href: 'https://xceed.me/brand' },
  { label: 'common:careers', href: 'https://angel.co/company/xceed/jobs' },
  { label: 'common:data', href: '/the-future/covid19' },
  { label: 'common:help', href: 'https://support.xceed.me/en' },
  { label: 'common:nightMag', href: 'https://xceed.me/blog' },
  { label: 'common:press', href: '/static/press/xceed-press-kit.zip', download: 'Xceed Press Kit' },
  { label: 'common:privacy', href: '/privacy-policy' },
  { label: 'common:terms', href: '/tos' },
];

const BLOG_LANGS = ['en', 'es', 'fr', 'it', 'pt'];

const SOCIALS = [
  { SocialIcon: Instagram, href: 'https://www.instagram.com/xceed_official/' },
  { SocialIcon: Facebook, href: 'https://www.facebook.com/Xceed/' },
  { SocialIcon: Twitter, href: 'https://twitter.com/xceed_official' },
  { SocialIcon: Youtube, href: 'https://www.youtube.com/c/xceedmepartytonight' },
  { SocialIcon: Vimeo, href: 'https://vimeo.com/xceed' },
  { SocialIcon: Linkedin, href: 'https://www.linkedin.com/company/xceed-org-' },
  { SocialIcon: Soundcloud, href: 'https://soundcloud.com/xceed-music' },
  { SocialIcon: Spotify, href: 'https://open.spotify.com/user/xceedmusic' },
  { SocialIcon: Tiktok, href: 'https://www.tiktok.com/@xceed_official' },
];

const APPS = [
  { AppIcon: AppStore, href: 'https://itunes.apple.com/us/app/xceed-party-tonight/id955085811' },
  { AppIcon: GooglePlay, href: 'https://play.google.com/store/apps/details?id=me.xceed.party' },
];

const getNews = (t, lang) => {
  let selectedLang = lang;
  if (lang === 'de' || lang === 'pt') selectedLang = 'en';
  if (lang === 'ca') selectedLang = 'es';
  const [clubbing, culture, music, top10] = [
    t('common:clubbing'),
    t('common:culture'),
    t('common:music'),
    t('common:top10'),
  ];
  const dict = {
    en: [
      { label: clubbing, href: 'https://xceed.me/blog/en/category/clubbing' },
      { label: culture, href: 'https://xceed.me/blog/en/category/culture' },
      { label: music, href: 'https://xceed.me/blog/en/category/music' },
      { label: top10, href: 'https://xceed.me/blog/en/category/top10' },
    ],
    fr: [
      { label: clubbing, href: 'https://xceed.me/blog/fr/category/clubbing-fr' },
      { label: culture, href: 'https://xceed.me/blog/fr/category/culture-fr' },
      { label: music, href: 'https://xceed.me/blog/fr/category/musique' },
      { label: top10, href: 'https://xceed.me/blog/fr/category/top10-fr' },
    ],
    es: [
      { label: clubbing, href: 'https://xceed.me/blog/es/category/clubbing-es' },
      { label: culture, href: 'https://xceed.me/blog/es/category/cultura-es' },
      { label: music, href: 'https://xceed.me/blog/es/category/musica' },
      { label: top10, href: 'https://xceed.me/blog/es/category/top10-es' },
    ],
    it: [
      { label: clubbing, href: 'https://xceed.me/blog/it/category/clubbing-it' },
      { label: culture, href: 'https://xceed.me/blog/it/category/cultura' },
      { label: music, href: 'https://xceed.me/blog/it/category/musica-it' },
      { label: top10, href: 'https://xceed.me/blog/it/category/top10-it' },
    ],
  };
  return dict[selectedLang];
};

const FooterSection = React.memo(function FooterSection({ title, data, ...props }) {
  const [isCollapsed, toggleExpansion] = useToggle(true);
  const isMobileOrTablet = useMediaQuery('mobile', 'tablet');
  const isDesktop = !isMobileOrTablet;

  const iconSpring = useSpring({
    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
  });

  return (
    <Col
      width={[1, 1, 1 / 4]}
      paddingY={3}
      onClick={isDesktop ? null : toggleExpansion}
      borderTop={[BORDER_TOP, BORDER_TOP, 'none']}
      {...props}
    >
      <Row alignItems="center">
        <Col flex={1}>
          <StrongText>{title}</StrongText>
        </Col>
        {!isDesktop && (
          <RotationCol style={iconSpring} width="min-content">
            <Icon color="brandDark" fontSize={1}>
              <ChevronDown />
            </Icon>
          </RotationCol>
        )}
      </Row>
      <Collapse isCollapsed={!isDesktop && isCollapsed} collapsedHeight={0}>
        {data?.map(({ href, as, label }, id) => (
          <Box key={id} width="fit-content">
            <Link href={href} as={as} aria-label={label}>
              <SecondaryText lineHeight={2}>{label}</SecondaryText>
            </Link>
          </Box>
        ))}
      </Collapse>
    </Col>
  );
});

FooterSection.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      href: PropTypes.string,
      as: PropTypes.string,
    })
  ).isRequired,
};

function Footer({ hasSeparator, ...props }) {
  const [{ popularCities }] = useCities();

  const { t, lang } = useTranslation();
  const isMobileOrTablet = useMediaQuery('mobile', 'tablet');
  const isDesktop = !isMobileOrTablet;
  const currentYear = new Date().getFullYear();

  const pages = useMemo(
    () =>
      PAGES.map(page => {
        if (page.label !== 'common:nightMag') return page;
        const blogLang = BLOG_LANGS.find(el => el === lang) || 'en';
        return { ...page, href: `${page.href}/${blogLang}` };
      }),
    [lang]
  );

  const business = useMemo(
    () => ({
      title: t('common:footerBusiness'),
      data: BUSINESS.filter(({ label }) => {
        if (label === 'common:siaeAutomatedTicketOffice') {
          return lang === 'it';
        }
        return true;
      }).map(({ href, label }) => ({
        label: t(label),
        href,
        as: href,
      })),
      borderTop: 'none',
    }),
    [lang]
  );

  const goOutInCities = useMemo(
    () => ({
      title: t('common:goOut'),
      data: popularCities.map(city => ({
        label: t('common:goOutIn', { city: city.name }),
        href: '/[geo]',
        as: `/${city.slug}`,
      })),
    }),
    [popularCities, lang]
  );

  const events = useMemo(
    () => ({
      title: t('common:event_plural'),
      data: popularCities.map(city => ({
        label: t('common:eventsIn', { city: city.name }),
        href: '/[geo]/events',
        as: `/${city.slug}/events`,
      })),
    }),
    [popularCities, lang]
  );

  const clubs = useMemo(
    () => ({
      title: t('common:club_plural'),
      data: popularCities.map(city => ({
        label: t('common:clubsIn', { city: city.name }),
        href: '/[geo]/clubs',
        as: `/${city.slug}/clubs`,
      })),
    }),
    [popularCities, lang]
  );

  const popularVenues = useMemo(
    () => ({
      title: t('common:popularVenues'),
      data: POPULAR_VENUES.map(venue => ({
        label: venue.name,
        href: '/[geo]/club/[...params]',
        as: `/${venue.geo}/club/${venue.slug}`,
      })),
    }),
    [lang]
  );

  const artists = useMemo(
    () => ({
      title: t('common:artist_plural'),
      data: popularCities.map(city => ({
        label: t('common:djsIn', { city: city.name }),
        href: '/[geo]/artists',
        as: `/${city.slug}/artists`,
      })),
    }),
    [popularCities, lang]
  );

  const topHeadliners = useMemo(
    () => ({
      title: t('common:topHeadliners'),
      data: TOP_HEADLINERS.map(artist => ({
        label: artist.name,
        href: '/artist/[...params]',
        as: `/artist/${artist.slug}`,
      })),
    }),
    [lang]
  );

  const news = useMemo(
    () => ({
      title: t('common:news'),
      data: getNews(t, lang),
    }),
    [lang]
  );

  const SocialList = useMemo(
    () => (
      <Row justifyContent="center">
        {SOCIALS.map(({ SocialIcon, href }, id) => (
          <Col key={id} width="auto" paddingY={1}>
            <Link href={href} aria-label="Social icon" color="brandDark">
              <Icon color="brandDark" fontSize={4}>
                <SocialIcon />
              </Icon>
            </Link>
          </Col>
        ))}
      </Row>
    ),
    []
  );

  const GoOut = useMemo(() => <SecondaryText>{t('common:footerMotto')}</SecondaryText>, [lang]);

  const Apps = useMemo(
    () => (
      <Flex height="100%" alignItems="flex-end" justifyContent="flex-end">
        {APPS.map(({ AppIcon, href }, id) => (
          <Link key={id} href={href} aria-label="app icon">
            <Icon color="brandDark" fontSize={125}>
              <AppIcon height={50} />
            </Icon>
          </Link>
        ))}
      </Flex>
    ),
    []
  );

  const firstRow = [business, goOutInCities, events, clubs];
  const secondRow = [popularVenues, artists, topHeadliners, news];

  return (
    <FullWidthContainer position="relative" backgroundColor="brandLight">
      <Container
        padding={2}
        paddingBottom={4}
        style={{
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
          hyphens: 'auto',
        }}
        {...props}
      >
        <Flex width={1} flexDirection="column" borderTop={hasSeparator ? BORDER_TOP : 'none'}>
          <Box width={1} paddingX={2}>
            <Row>
              {firstRow.map((el, id) => (
                <FooterSection key={id} {...el} />
              ))}
            </Row>
            <Row position="relative" borderTop={['none', 'none', BORDER_TOP]}>
              {secondRow.map((el, id) => (
                <FooterSection key={id} {...el} />
              ))}
              {isDesktop && (
                <Col
                  position="absolute"
                  bottom={0}
                  right={0}
                  width={[1, 1, 1 / 4]}
                  paddingBottom={3}
                >
                  {Apps}
                </Col>
              )}
            </Row>
          </Box>
          <Flex
            width={1}
            paddingX={2}
            paddingY={[3, 3, 0]}
            borderTop={BORDER_TOP}
            flexDirection={['column', 'column', 'row']}
            alignItems="center"
          >
            {!isDesktop && Apps}
            <Row width={1} flex={1}>
              {pages.map(({ label, href, ...linkProps }, id) => (
                <Col key={id} width={[1 / 3, 1 / 3, 'auto']} paddingY={3}>
                  <Link href={href} as={href} aria-label={label} {...linkProps}>
                    <StrongText width={1} textAlign={['center', 'center', 'left']}>
                      {t(label)}
                    </StrongText>
                  </Link>
                </Col>
              ))}
              <Col width={[1 / 3, 1 / 3, 'auto']} paddingY={3}>
                <Button
                  backgroundColor="transparent"
                  boxShadow={0}
                  padding={0}
                  type="button"
                  onClick={event => {
                    event.preventDefault();
                    // eslint-disable-next-line no-undef
                    if (OneTrust) OneTrust.ToggleInfoDisplay(); // Abrir el panel de configuración de cookies
                  }}
                >
                  <StrongText width={1} textAlign={['center', 'center', 'left']}>
                    {t('common:cookies')}
                  </StrongText>
                </Button>
              </Col>
            </Row>
            <LangSwitcher />
          </Flex>
          <Box width={1} paddingX={2} borderTop={BORDER_TOP}>
            <Row
              flexDirection={['column', 'column', 'row']}
              justifyContent={['center', 'center', 'space-between']}
              alignItems="center"
              paddingY={3}
            >
              <Col width="auto">
                <Link href="/" as="/" aria-label="homepage">
                  <XceedLogo width={150} fill="#36424a" />
                </Link>
              </Col>
              <Col width="auto" paddingTop={[2, 2, 0]}>
                {isDesktop ? SocialList : GoOut}
              </Col>
            </Row>
            <Row
              flexDirection={['column', 'column', 'row']}
              justifyContent={['center', 'center', 'space-between']}
              alignItems="center"
            >
              <Col width="auto">{isDesktop ? GoOut : SocialList}</Col>
              <Col width="auto" paddingTop={[2, 2, 0]}>
                <Flex alignItems="center">
                  <Icon fontSize={3} mr={1} mb="2px" color="black">
                    <Xceed />
                  </Icon>
                  <SecondaryText>© {currentYear} Xceed</SecondaryText>
                </Flex>
              </Col>
            </Row>
          </Box>
        </Flex>
      </Container>
    </FullWidthContainer>
  );
}

Footer.defaultProps = {
  hasSeparator: false,
};

export default React.memo(Footer);
